import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import './App.css';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import MyComponent from './barra'
Amplify.configure(awsExports);

function Soporte() {
    return (
      <div>
          <MyComponent/>
          <div id="titulo">
    <div class="row row-sm-w-100 py-3 text-center text-primary font-weight-bold">
      <div class="row">
        <h1 class="text-center " id="h">Soporte</h1>
      </div>
    </div>
  </div>
  <div id="Nosotros">
    <div class="container-md p-4">
      <h3>Contactenos</h3>
      <div class="row">
        <p>
            Beacons IoT Technologies
            <br></br>
            Contacto: 3146148505
            <br></br>
            Email: beaconsiottechnologies@gmail.com
            <br></br>
            Dirección: Calle 65e no 91a - 53
        </p>
      </div>
    </div>
  </div>
      </div>
    );
  }
  export default withAuthenticator(Soporte);