import React, {useState, useEffect} from 'react';
import io from "socket.io-client";

function Mapeo(username){
  const [imageSrc, setImageSrc] = useState("");
  let clientConfig = {
    imagePaths: {
      sala: '../sala.png',
      habitacion: '../habitacion.png',
      cocina: '../cocina.png',
      masas: '../Masas.png',
      piso1: '../Piso1.png',
    },
    conditions: {
      baliza: 'Baliza_cliente1',
      electrobisturi: 'electrobisturi',
      pipeta: 'pipeta',
      pipeta1: 'pipeta1',
      pipeta2: 'pipeta2',
    },
  };
  let clientConfig1 = {
    imagePaths: {
      sala: '../room.png',
      habitacion: '../kitchen.png',
      cocina: '../office.png',
      masas: '../tv.png',
      piso1: '../habi2.png',
    },
    conditions: {
      baliza: 'Baliza_cliente1',
      electrobisturi: 'electrobisturi',
      pipeta: 'pipeta',
      pipeta1: 'pipeta1',
      pipeta2: 'pipeta2',
    },
  };
    useEffect(() => {
        const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
        let dict;
        if(username.username === 'johnatan'){
          dict = clientConfig1;
        }
        if(username.username === 'laura'){
          dict = clientConfig;
        }
        const socket = io(socket_url, {
          transports: ["websocket"]
        });
        socket.on('connect', () => {
          socket.emit('enlace_azul', username.username);
        });
        socket.on('datos', (data) => {
            let d = Math.round(Math.pow(10,((-59-(data.distancia?.rssi))/(10*2.7)))*100)/100;
            let d1 = Math.round(Math.pow(10,((-59-(data.distancia1?.rssi))/(10*2.7)))*100)/100;
            let d2 = Math.round(Math.pow(10,((-59-(data.distancia2?.rssi))/(10*2.7)))*100)/100;
            let d3 = Math.round(Math.pow(10,((-59-(data.distancia3?.rssi))/(10*2.7)))*100)/100;
            let local = '';
            let activo = '';
            if(isNaN(d)){
                d = 51
              }
              if(isNaN(d1)){
                d1 = 51
              }
              if(isNaN(d2)){
                d2 = 51
              }
              if(isNaN(d3)){
                d3 = 51
              }
              let menor = Math.min(d,d1,d2,d3,50);
              if(d === menor){
                local = 'Johnatan Mena Room'
                setImageSrc(dict.imagePaths.sala)
              }
              if(d1 === menor){
                local = 'Johnatan Mena Kitchen'
                setImageSrc(dict.imagePaths.habitacion)
              }
              if(d2 === menor){
                local = 'Johnatan Home-Office'
                setImageSrc(dict.imagePaths.cocina)
              }
              if(d3 === menor){
                local = 'Johnatan Mena Living Room'
                setImageSrc(dict.imagePaths.masas)
              }
              if(menor===50){
                menor = 0
                local = 'Activo no encontrado'
                setImageSrc(dict.imagePaths.piso1)
              }
              if(data.dmac === 'BC572903142E'){
                activo = 'Tomógrafo Torre 1'
              }
              if(data.dmac === 'BC57290406DC'){
                activo = 'Rayos X portátil'
              }
              if(data.dmac === 'BC572903F515'){
                activo = 'Flujómetro Rayos x'
              }
              if(data.dmac === 'BC5729031435'){
                activo = 'Baliza terminada en 1435'
              }
              if(data.dmac === 'BC572903F578'){
                activo = 'Localizador F578'
              }
              document.getElementById("tracker").innerHTML = '  Escaneando:  ' + activo
              document.getElementById("mapa").innerHTML = '  Localizado en ' + local + ' a ' + menor + ' Metros'
        });
        return () => {
          socket.disconnect();
        };
      }, []);
return(
    <div>
        <h7 class="text-center text-primary h3 " id="tracker">''</h7>
        <h7 class="text-center text-primary h3 " id="mapa">No encontrado</h7>
        <div id='grafico'>
        <img src={imageSrc} alt="Logo" width="320"/>
        </div>
        
    </div>
)
}

export default Mapeo