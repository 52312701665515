import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import MyComponent from './barra';

Amplify.configure(awsExports);

function App({ signOut, user }) {
  return (
    <>
      <MyComponent/>
    </>
  );
}
export default withAuthenticator(App);
