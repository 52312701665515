import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './login';
import Inicio from './inicio';
import Scan from './scan';
import Scan_c2 from './scan_c2';
import Soporte from './soporte';
import historico from './historico';
import Scan1 from './scan1';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
const AuthenticatedInicio = withAuthenticator(Inicio);
const AuthenticatedScan = withAuthenticator(Scan);
const AuthenticatedScan_c2 = withAuthenticator(Scan_c2);
const AuthenticatedSoporte = withAuthenticator(Soporte);
const AuthenticatedHistorico = withAuthenticator(historico);
const AuthenticatedScan1 = withAuthenticator(Scan1);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/inicio" element={<AuthenticatedInicio />} />
          <Route path="/scan" element={<AuthenticatedScan />} />
          <Route path="/scan_c2" element={<AuthenticatedScan_c2/>} />
          <Route path="/soporte" element={<AuthenticatedSoporte />} />
          <Route path="/historico" element={<AuthenticatedHistorico />} />
          <Route path="/filtros" element={<AuthenticatedScan1 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

