import {
  Authenticator,
  Button,
  Heading,
  Image,
  Text,
  translations,
  useAuthenticator,
  useTheme,
  View
} from '@aws-amplify/ui-react';
import React from 'react';
import Render from './auth';
import { Amplify, I18n } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';

I18n.putVocabularies(translations);
I18n.setLanguage('es');
console.log(translations)
I18n.putVocabularies({
  es: {
    'Sign In': 'Registrarse',
    'Sign Up': 'Regístrate'
  }
});

Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Amplify logo"
          src="/bar.png"
          style={{ width: 1000, marginBottom: 15 }}
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; Beacons IoT Technologies
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <View style={{ paddingRight: 32, paddingLeft: 32 }}>
          <Heading padding={`${tokens.space.xl}`} level={3}>
            Enlace azul 
          </Heading>
          {/* Button background color should be #0056b4 but form button colors must be changed on AWS*/}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
          </div>
        </View>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            ¿Olvidaste tu contraseña?
          </Button>
        </View>
      );
    }
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Crear una cuenta nueva
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    }
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information :
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  }
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: 'Ingrese el nombre del usuario'
    }
  },
  signUp: {
    password: {
      labelHidden: false,
      label: 'Clave:',
      placeholder: 'Ingrese la contraseña',
      isRequired: false,
      order: 1
    },
    confirm_password: {
      labelHidden: false,
      label: 'Confirmar contraseña',
      order: 2
    }
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: 'Ingrese la contraseña:'
    }
  },
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: 'username'
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Ingrese el código de confirmación:',
      label: 'New Label',
      isRequired: false
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Ingrese por favor su contraseña:'
    }
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user'
    },
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false
    }
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false
    }
  }
};

function Login({signOut, user}) {
  return (
    <div>
    <Authenticator
      data-testid="app-condition"
      hideSignUp={true}
      formFields={formFields}
      components={components}
    >
     <Render />
     </Authenticator>
    </div>
  );
}
export default Login;