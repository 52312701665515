import React, { useRef, useEffect } from 'react';
import './App.css';
import io from "socket.io-client";

function Mapa7(username) {
  let vista3 = useRef(null);
  useEffect(() => {
  const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
  const socket = io(socket_url,  {
    transports: ["websocket"]
  });
  socket.on('connect', () => {
    socket.emit('enlace_azul', username.username);
  });
  socket.on('datos', (data) => {
    const ch1 = document.getElementById("ch1");
    const ch2 = document.getElementById("ch2");
    const ch3 = document.getElementById("ch3");
    const ch4 = document.getElementById("ch4");

    if (ch2 && ch3 && ch4) {
    document.getElementById("ch2").innerHTML = data.distancia6?.temp;
    let d6 = Math.round(Math.pow(10,((-59-(data.distancia6?.rssi))/(10*2.7)))*100)/100;;
    document.getElementById("ch3").innerHTML = d6
    document.getElementById("ch4").innerHTML = data.distancia6?.time;
    document.getElementById("ch1").innerHTML = data.distancia6?.humidty;
    if (isNaN(d6) && vista3.current) {
      vista3.current.style.display = 'none';
    } else if (vista3.current) {
      vista3.current.style.display = '';
    }
    }
  });
}, []);
    return (
      <div>
   <table ref={vista3} border="1">
  <thead>
    <tr>
      <th scope="col">Ubicación</th>
      <th scope="col">Temp (grados)</th>
      <th scope="col">Humedad (%)</th>
      <th scope="col">Fecha y hora</th>
      <th scope="col">Distancia</th>
    </tr>
  </thead>
  <tbody>
              <tr>
                  <td>Urología: </td>
                  <td id="ch2" data-sortable="true"></td>
                  <td id="ch1" data-sortable="true"></td>
                  <td id="ch4" data-sortable="true"></td>
                  <td id="ch3" data-sortable="true">
                  </td>
                </tr>
  </tbody>
</table>
      </div>

    );
  }
  export default Mapa7;