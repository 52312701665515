import React, { useRef, useEffect } from 'react';
import './App.css';
import io from "socket.io-client";

function Mapa2(username) {
  let vista = useRef(null);
  useEffect(() => {
  const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
  const socket = io(socket_url,  {
    transports: ["websocket"]
  });
  socket.on('connect', () => {
    socket.emit('enlace_azul', username.username);
  });
  socket.on('datos', (data) => {
    const sc1 = document.getElementById("sc1");
    const sc2 = document.getElementById("sc2");
    const sc3 = document.getElementById("sc3");
    const sc4 = document.getElementById("sc4");

    if (sc2 && sc3 && sc4) {
    document.getElementById("sc2").innerHTML = data.distancia?.temp;
    let d = Math.round(Math.pow(10,((-59-(data.distancia?.rssi))/(10*2.7)))*100)/100;;
    document.getElementById("sc3").innerHTML = d
    document.getElementById("sc4").innerHTML = data.distancia?.time;
    document.getElementById("sc1").innerHTML = data.distancia?.humidty;
    if (isNaN(d) && vista.current) {
      vista.current.style.display = 'none';
    } else if (vista.current) {
      vista.current.style.display = '';
    }
    }
  });
}, []);
    return (
      <div>
     <table ref={vista} border="1">
    <thead>
      <tr>
        <th scope="col">Ubicación</th>
        <th scope="col">Temp (grados)</th>
        <th scope="col">Humedad (%)</th>
        <th scope="col">Fecha y hora</th>
        <th scope="col">Distancia</th>
      </tr>
    </thead>
    <tbody>
                <tr>
                    <td>Johnatan Mena Room: </td>
                    <td id="sc2"></td>
                    <td id="sc1"></td>
                    <td id="sc4"></td>
                    <td id="sc3"></td>
                  </tr>
    </tbody>
</table>
      </div>

    );
  }
  export default Mapa2;