import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { withAuthenticator } from '@aws-amplify/ui-react';
import './App.css';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

function Inicio({signOut,user}) {
  if(user.username === 'johnatan'){
    return (
      <div>
       <Navbar className='celeste' expand="lg" >
        <Navbar.Brand href="#"><img src="../Logo.bmp" alt="Logo" width="100"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="negrita">
            <Nav.Link href="/inicio">Inicio</Nav.Link>
            <Nav.Link href="/scan">Escaner</Nav.Link>
            {/* <Nav.Link href="/filtros">Áreas</Nav.Link> */}
            <Nav.Link href="/historico">Histórico</Nav.Link>
            <Nav.Link href="/soporte">Soporte</Nav.Link>
            <button class="btn btn-outline-dark" onClick={signOut}>Salir</button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
      <img src='../Logo.png' alt="Logo" width="400"/>
        </div>
      </div>
    );
  }
  if(user.username === 'laura'){
    return (
      <div>
       <Navbar className='celeste' expand="lg" >
        <Navbar.Brand href="#"><img src="../Logo.bmp" alt="Logo" width="100"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="negrita">
            <Nav.Link href="/inicio">Inicio</Nav.Link>
            <Nav.Link href="/scan_c2">Escaner</Nav.Link>
            {/* <Nav.Link href="/filtros">Áreas</Nav.Link> */}
            <Nav.Link href="/historico">Histórico</Nav.Link>
            <Nav.Link href="/soporte">Soporte</Nav.Link>
            <button class="btn btn-outline-dark" onClick={signOut}>Salir</button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      </div>
    );

  }
  }
  export default withAuthenticator(Inicio);