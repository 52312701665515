import React, { useRef, useEffect, useState } from 'react';
import io from "socket.io-client";


function Mapatable() {
  let vista5 = useRef(null);
  const [dataArea,setArea] = useState([]);
    useEffect(() => {
    const nombreEspacio = 'johnatan';
    const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
    const socket = io(socket_url, {
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      socket.emit('enlace_azul', nombreEspacio);
    });
    socket.on('datos', (data) => {
      setArea(data.area)
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <table ref={vista5} border="border: 1px solid black">
        <thead>
          <tr>
            <th scope="col">Dmac</th>
            <th scope="col">Fecha y hora</th>
            <th scope="col">Distancia</th>
          </tr>
        </thead>
        <tbody>
        {dataArea.length > 0 && dataArea?.map((element, index) => (
            <tr key={index}>
              <td>{element?.dmac}</td>
              <td>{element?.time}</td>
              <td>
                {(
                  Math.round(Math.pow(10,((-59-(element?.rssi))/(10*2.7)))*100)/100
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Mapatable;
